<template>
  <div class="body1">
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link :to="{name: 'home'}">{{ $t('home') }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        {{ $t('settings') }}
      </a-breadcrumb-item>
    </a-breadcrumb>
    <a-row :gutter="[15,0]" style="margin-top: 20px">
      <a-col :span="5">
        <a-menu mode="inline" :selectedKeys="['settings']">
          <a-menu-item key="settings">
            <template #icon>
              <SettingOutlined />
            </template>
            <router-link :to="{name: 'account_setting'}">{{ $t('settings') }}</router-link>
          </a-menu-item>
          <a-menu-item key="balances">
            <template #icon>
              <AccountBookOutlined />
            </template>
            <router-link :to="{name: 'account_credit_balance'}">{{ $t('account_credit_balance') }}</router-link>
          </a-menu-item>
          <a-menu-item key="messages">
            <template #icon>
              <AlertOutlined />
            </template>
            <router-link :to="{name: 'account_message'}"><a-badge :count="currentUser.UnreadMesNumber" :offset="[100, 5]">{{ $t('messages') }}</a-badge></router-link>
          </a-menu-item>
        </a-menu>
      </a-col>
      <a-col :span="19" class="border list">
        <div style="display: flex;justify-content: space-between;align-items:center;">
          <div>
            <strong>{{ $t('user_info') }}</strong>
          </div>
          <div>
            <a-button type="link" size="small" @click="updateVisible=true">{{ $ta('update|user_info', 'p') }}</a-button>
            <a-button type="link" size="small" @click="passwordVisible=true">{{ $ta('update|password', 'p') }}</a-button>
          </div>
        </div>
        <a-divider />
        <div>
          <table>
            <tr>
              <th>{{ $t('username') }}</th>
              <td>{{ currentUser.login_name }}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>{{ currentUser.user_email }}</td>
            </tr>
            <tr>
              <th>{{ $t('full_name') }}</th>
              <td>{{ currentUser.user_name }}</td>
            </tr>
            <tr>
              <th>{{ $t('mobile_number') }}</th>
              <td>{{ currentUser.user_tel }}</td>
            </tr>
            <tr>
              <th>{{ $t('notice_emails') }}</th>
              <td v-html="currentUser.notice_emails?.replace(/;/g, '<br />')"></td>
            </tr>
          </table>
        </div>
        <div style="display: flex;justify-content: space-between;">
          <div>
            <strong>{{ $t('org_info')}}</strong>
          </div>
          <div>

          </div>
        </div>
        <a-divider />
        <div>
          <table>
            <tr>
              <th>{{ $t('company_code') }}</th>
              <td>{{ currentUser.channel.toUpperCase() }}</td>
            </tr>
            <tr>
              <th>{{ $t('company_name') }}</th>
              <td>{{ currentUser.company_name }}</td>
            </tr>
            <tr>
              <th>{{ $t('company_city') }}</th>
              <td>{{ currentUser.country }} {{ currentUser.province }} {{ currentUser.city }}</td>
            </tr>
            <tr>
              <th>{{ $t('contact_address') }}</th>
              <td>{{ currentUser.address }}</td>
            </tr>
            <tr>
              <th>{{ $t('contact_tel') }}</th>
              <td>{{ currentUser.tel }}</td>
            </tr>
            <tr>
              <th>{{ $t('credit_quota') }}</th>
              <td>{{currentUser.credit_currency }} {{ $filters.formatAmount(currentUser.credit_quota,0) }}</td>
            </tr>
            <tr>
              <th>{{ $t('credit_balance') }}</th>
              <td>{{currentUser.credit_currency }} {{ $filters.formatAmount(currentUser.credit_balance,0) }}</td>
            </tr>
          </table>
        </div>
      </a-col>
    </a-row>
  </div>

  <a-modal v-model:visible="updateVisible" :maskClosable="false" :closable="false" :title="$ta('update|user_info', 'p')" :ok-text="$t('submit')" :confirm-loading="loading" :ok-button-props="{ htmlType: 'submit', form: 'form_update_info' }">
    <a-form id="form_update_info" name="form_update_info" layout="vertical" :colon="false" :hideRequiredMark="true" autocomplete="off" :model="updateForm" :rules="updateFormRules" @finish="finishUpdateInfo">
      <a-form-item name="user_name" :label="$t('full_name')">
        <a-input v-model:value="updateForm.user_name"/>
      </a-form-item>
      <a-form-item name="user_tel" :label="$t('mobile_number')">
        <a-input v-model:value="updateForm.user_tel"/>
      </a-form-item>
      <a-form-item name="notice_emails" :label="$t('notice_emails')">
        <a-input v-model:value="updateForm.notice_emails"/>
      </a-form-item>
    </a-form>
  </a-modal>

  <a-modal v-model:visible="passwordVisible" :maskClosable="false" :closable="false" :title="$ta('update|password', 'p')" :ok-text="$t('submit')" :confirm-loading="loading" :ok-button-props="{ htmlType: 'submit', form: 'form_password' }">
    <a-form id="form_password" name="form_password" layout="vertical" :colon="false" :hideRequiredMark="true" autocomplete="off" :model="passwordForm" :rules="passwordFormRules" @finish="finishPwd">
      <a-form-item name="old_pwd" :label="$ta('current|password','p')">
        <a-input-password v-model:value="passwordForm.old_pwd"/>
      </a-form-item>
      <a-form-item name="new_pwd" :label="$t('new_password')">
        <a-input-password v-model:value="passwordForm.new_pwd"/>
      </a-form-item>
      <a-form-item name="new_pwd1" :label="$t('re_new_password')">
        <a-input-password v-model:value="passwordForm.new_pwd1"/>
      </a-form-item>
    </a-form>
  </a-modal>

</template>

<script>
import {
  AccountBookOutlined,
  SettingOutlined,
  AlertOutlined
} from '@ant-design/icons-vue';
import {updateInfo,updatePwd} from "@/services/account";
import {Modal} from "ant-design-vue";

export default {
  name: "Setting",
  components: {
    AccountBookOutlined,
    SettingOutlined,
    AlertOutlined
  },
  data(){
    const currentUser = this.$store.getters["account/getUser"]

    return {
      currentUser,
      loading:false,
      updateVisible: false,
      updateForm:{
        user_name : currentUser.user_name,
        user_tel: currentUser.user_tel,
        notice_emails: currentUser.notice_emails
      },
      updateFormRules:{
        user_name: [{
          required: true,
          message: '',
        }],
        user_tel: [{
          required: true,
          message: '',
        }],
      },
      passwordVisible: false,
      passwordForm: {
        old_pwd: '',
        new_pwd: '',
        new_pwd1: ''
      },
      passwordFormRules:{
        old_pwd: [{
          required: true,
          message: '',
        }],
        new_pwd: [{
          required: true,
          asyncValidator: async (_rule, value) => {
            if (value === '') {
              return Promise.reject('');
            } else if(value.length < 10){
              return Promise.reject(this.$t("passwords_min_length"));
            } else {
              return Promise.resolve();
            }
          },
        }],
        new_pwd1: [{
          required: true,
          asyncValidator: async (_rule, value) => {
            if (value === '') {
              return Promise.reject('');
            } else if (value !== this.passwordForm.new_pwd) {
              return Promise.reject(this.$t("passwords_not_match"));
            } else {
              return Promise.resolve();
            }
          },
        }],
      },
    }
  },
  methods:{
    finishUpdateInfo(){
      this.loading = true
      updateInfo(this.updateForm).then(res => {
        this.loading = false
        const resData = res.data
        if (resData.Success) {
          Modal.success({
            centered: true,
            title: this.$ta('update|success'),
            onOk: function(){
              location.reload()
            }
          });
        } else {
          Modal.error({
            centered: true,
            title: this.$t('error.' + resData.Code),
            okText: null
          });
        }
      }).catch(error => {
        console.log(error)
      })
    },
    finishPwd(){
      this.loading = true
      updatePwd(this.passwordForm).then(res => {
        this.loading = false
        const resData = res.data
        if (resData.Success) {
          Modal.success({
            centered: true,
            title: this.$ta('update|success'),
            onOk: function(){
              this.passwordVisible = false
            }
          });
        } else {
          Modal.error({
            centered: true,
            title: this.$t('error.' + resData.Code),
            okText: null
          });
        }
      }).catch(error => {
        console.log(error)
      })
    }
  },
}
</script>

<style scoped>
.list {
  padding: 10px 15px 20px 15px;
  margin-bottom: 30px;
}
.list .ant-divider-horizontal {
  margin: 10px 0;
}
.list strong {
  padding-left: 5px;
}
.list table {
  width: 100%;
  margin: 0 0 20px 0;
}
.list th {
  text-align: right;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.06);
  font-size: 13px;
  padding: 10px;
  width: 20%;
}
.list td {
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
</style>